<template>
  <div>
    <h1>H1 Text</h1>
    <h2>H2 Text</h2>
    <h3>H3 Text</h3>
    <h4>H4 Text</h4>
    <h5>H5 Text</h5>
    <h6>H6 Text</h6>

    <p>Default Paragraph</p>
    <p class="small">Small Text</p>
    <p class="medium">Medium Text</p>
    <p class="large">Large Text</p>
    <p><b>Bold Text</b></p>
  </div>
</template>

<script>
export default {
  name: "Template",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography.scss";

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
